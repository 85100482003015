let BACKEND_SERVER = null;
let API_TOKEN = null;

// create .env file and pass the api url to REACT_APP_BACKEND_SERVER
BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
API_TOKEN = process.env.REACT_APP_TOKEN;
const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/hps/react/default'
    basename: '',
    defaultPath: '/dashboard/stories',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    API_SERVER: BACKEND_SERVER,
    TOKEN: API_TOKEN
};

export default config;
