import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MinimalLayout from './../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// dashboard routing
const Story = Loadable(lazy(() => import('../views/dashboard/Story')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    return (
        <Route path={['/dashboard/stories',]}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/stories" component={Story} />
                    </AuthGuard>
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default MainRoutes;
