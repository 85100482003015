// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';
import configData from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    description: null,
    story: null,
    stories: [],
    file: null,
    files: [],
    campus: null,
    campuses: [],
    isLoading: true
};

const slice = createSlice({
    name: 'story',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        // GET STORY
        getStorySuccess(state, action) {
            state.story = action.payload;
            state.isLoading = false;
        },

        // GET STORIES
        getStoriesSuccess(state, action) {
            state.stories = action.payload;
            state.isLoading = false;
        },

        // SET LOADING
        setLoadingSuccess(state, action){
            state.isLoading = action.payload
        },

        // GET CAMPUS
        getCampusSuccess(state, action) {
            state.campus = action.payload;
            state.isLoading = false;
        },

        // GET CAMPUSES
        getCampusesSuccess(state, action) {
            state.campuses = action.payload;
            state.isLoading = false;
        },

        // GET FILE
        getFileSuccess(state, action) {
            state.file = action.payload;
            state.isLoading = false;
        },

        // GET FILES
        getFilesSuccess(state, action) {
            state.files = action.payload;
            state.isLoading = false;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCampuses() {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            const url = configData.API_SERVER + 'campus';
            const response = await axios.get(url,  { headers: { Authorization: 'Token ' + configData.TOKEN, } }); // this token can be found in auttoken_token table. if it doesn't exist. create one.
            dispatch(slice.actions.getCampusesSuccess(response.data.results))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getCampus(campus) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            dispatch(slice.actions.getCampusSuccess(campus))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function createStory(email, campus, desc, media) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            const url = configData.API_SERVER + 'story/upload';
            const data = new FormData();
            data.append('email', email);
            data.append('campus', campus);
            data.append('description', desc);
            data.append('file', media);
            
            const config = {
                headers: {
                'Authorization': 'Token ' + configData.TOKEN,
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                }
            };
            
            const response = await axios.post(url, data, config);
            dispatch(slice.actions.getStorySuccess(response.data));
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}